import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const ThankYouContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Section = styled.section`
  width: 95%;
  max-width: 1178px;
  margin: 0 auto;

  span {
    color: #c41a09;
    font-weight: 600;
  }
`;

const Text = styled.p`
  ${({ primary }) =>
    primary &&
    css`
      font-size: 1.2em;
      margin: 10px 0 15px;
    `}
`;

const Title = styled.h1`
  text-align: center;
  font-size: ${({ primary }) => (primary ? '2.5em' : '1.5em')};
`;

const SubTitle = styled.h3`
  color: ${({ color }) => color};

  span {
    font-size: 0.7em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: ${({ secondary }) => secondary && 'column'};
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const Button = styled.button`
  width: 40%;
  height: 60px;
  background: #223444;
  border: none;
  margin-bottom: 20px;
  font-size: 1.2em;
  color: #fff;
  transition: background 0.3s ease-in-out;
  outline: none;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 100%;
    max-width: 400px;
  }
`;

const GraphContainer = styled.div`
  width: 80%;
  max-width: 560px;
  margin: auto;
`;

const SubSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 90%;
  margin: auto;

  #awards-two {
    display: none;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    #awards-one {
      display: none;
    }
    #awards-two {
      display: block;
    }
  }
`;

const Image = styled.img`
  max-width: ${({ secondary }) => (secondary ? '400px' : '100%')};
  max-height: ${({ secondary }) => secondary && '250px'};
`;

const MidSection = styled.section`
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  border-radius: 5px;
  font-family: sans-serif;
  padding: 5px 10px 0;
  font-size: 0.8em;
`;

const Footer = styled.footer`
  width: 100%;
  padding: 30px 0 50px;
  background-color: #e8e8e8;
`;

// complete navigation logic
const DlThankYou = () => {
  const [initial, setInitial] = useState(true);
  const [adButler, setAdButler] = useState(false);
  const [adPID, setPID] = useState(350998);

  const buttonClick = () => {
    setInitial(false);
  };

  const adMap = [];

  for (let i = 0; i < 10; i += 1) {
    adMap.push(<AdButler key={i} number={adPID} />);
  }

  return (
    <>
      <ThankYouContainer>
        {initial ? (
          <Section>
            <Title primary>Congratulations!</Title>
            <Title>
              Did you know that approximately 2.4 million borrowers can now
              likely qualify to reduce their interest rates by 0.75% by
              refinancing their mortgage?
            </Title>
            <Text primary>
              <span>Step 1:</span> Click your age to see the average amount of
              equity available across the US by age group.
            </Text>
            <ButtonContainer>
              <Button onClick={buttonClick} type="submit">
                Under 35
              </Button>
              <Button onClick={buttonClick} type="submit">
                35-44
              </Button>
              <Button onClick={buttonClick} type="submit">
                45-54
              </Button>
              <Button onClick={buttonClick} type="submit">
                55-64
              </Button>
              <Button onClick={buttonClick} type="submit">
                65 and over
              </Button>
            </ButtonContainer>
          </Section>
        ) : (
          <Section>
            <GraphContainer>
              <Image
                src="https://s3-us-west-1.amazonaws.com/offers.suitedconnector.com/dl/images/home-equity-desktop.gif"
                id="home-equity"
                alt=""
              />
            </GraphContainer>
            <hr />
            <Text primary>
              <span>Step 2:</span> Get ready, we have matched you with the
              lender who has won the{' '}
              <strong>
                JD Power Customer Satisfaction Award Winner for 9 straight years
              </strong>{' '}
              – Quicken Loans.
            </Text>
            <SubSection>
              <div>
                <SubTitle>Featured Provider</SubTitle>
                <Image
                  src="https://s3-us-west-1.amazonaws.com/offers.suitedconnector.com/dl/images/quickenloans.png"
                  alt=""
                  secondary
                />
                <Title>America's largest mortgage lender</Title>
                <Text>Quicken Loans will contact you soon!</Text>
              </div>

              <Image
                src="https://s3-us-west-1.amazonaws.com/offers.suitedconnector.com/dl/images/jdp-combined.png"
                alt=""
                secondary
                id="awards-one"
              />

              <Image
                src="https://s3-us-west-1.amazonaws.com/offers.suitedconnector.com/dl/images/jdp-combined-mobile.png"
                alt=""
                secondary
                id="awards-two"
              />
            </SubSection>
            <hr />
            <div id="insurance-question">
              <MidSection>
                <SubTitle color="#c41a09">
                  Eligibilty Alert <span>(scroll down)</span>
                </SubTitle>
                <ul>
                  <li>
                    At least 85% of homeowners in the U.S. have homeowners
                    insurance, and policies cost an average of $1,083 per year.
                  </li>
                  <li>
                    The average homeowners insurance premium rose by over 3%
                    last year.
                  </li>
                  <li>
                    {' '}
                    Based on the information you submitted, it appears that you
                    could see significant savings on what you pay for home
                    insurance.{' '}
                  </li>
                </ul>
              </MidSection>

              <Text primary>
                <span>Step 3:</span> Are you interested in being connected with
                a home insurance specialist?
              </Text>
              <ButtonContainer secondary>
                <Button
                  type="submit"
                  id="home-insurance"
                  onClick={() => setAdButler(true)}
                >
                  Yes, Connect Me
                </Button>
                <Button type="submit" id="no-home-insurance">
                  No, I'm not Interested
                </Button>
              </ButtonContainer>
            </div>

            <Text primary>
              <span>Step 4:</span> Prefer to work with a different lender for
              any reason? Click below to be matched with a different lender.
            </Text>
            <ButtonContainer>
              <Button type="submit" className="additional-lender-btn">
                Find Another Lender
              </Button>
            </ButtonContainer>

            <div id="first-step-5">
              <Text primary>
                <span>Step 5:</span> Would you like to see other discounts
                available to you? Click below to learn more ways to easily save
                money each month.
              </Text>
              <ButtonContainer>
                <Button type="submit" className="listicle-btn">
                  More Discounts
                </Button>
              </ButtonContainer>
            </div>
          </Section>
        )}
        {adButler && adMap}
      </ThankYouContainer>
      <Footer>
        <Section>
          <h4>Sources:</h4>
          <Text>
            https://www.housingwire.com/articles/47881-more-mortgage-borrowers-now-have-incentive-to-refinance
          </Text>
          <Text>
            https://www.census.gov/newsroom/press-releases/2017/cb17-tps50-wealth-sipp.html
          </Text>
          <Text>
            https://www.quickenloans.com/blog/j-d-power-ranks-quicken-loans-1-customer-satisfaction-primary-mortgage-origination-9th-straight-year
          </Text>
        </Section>
      </Footer>
    </>
  );
};

export default withThankyouWrapper(DlThankYou);
